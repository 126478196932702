import { useRouteError } from 'react-router-dom'

export default function ErrorPage() {
    const error     = useRouteError();

    console.error(error);

    return (
        <>
            <h1 style={{color: "red", fontWeight: "bold"}}>
                {error.status} {error.statusText}
            </h1>

            <p>
                {error.data}
            </p>
        </>
    );
}