import Graphql from "../graphql";

class Queries {
    static createClient(data) {
        const pushData = async () => {
            const createClient  = `
                mutation CreateClient($input: CreateClientInput!, $condition: ModelClientConditionInput) {
                    createClient(input: $input, condition: $condition) {
                        leveraged
                        multiTimezone
                        userCount
                        timeZone
                        purchasedStoraged
                        internalSubnets
                        osVersion
                        sqlDriveLetter
                    }
                }
            `;

            let variables = {
                input: data
            }

            return await Graphql.query(createClient, variables);
        }

        return pushData();
    }

    static async getAvailableStacks() {
        const fetchData = async () => {
            const listStacks = `
                query ListStacks($filter: ModelStackFilterInput, $limit: Int, $nextToken: String) {
                    listStacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
                        items {
                            id
                            stackName
                            az1
                            az1Cidr
                            az2
                            az2Cidr
                            _deleted
                            _version
                        }
                    }
                }
            `;

            let variables = {
                filter: {
                    _deleted: {
                        ne: true
                    },
                    clientStacksClientId: {
                        attributeExists: false
                    }
                }
            }

            return await Graphql.query(listStacks, variables).then((gq) => {
                return gq.listStacks.items;
            });
        };

        return await fetchData();
    }

    static deleteClient(clientId, clientVersion) {
        const pushData = async () => {
            const deleteClient  = `
                mutation DeleteClient($input: DeleteClientInput!, $condition: ModelClientConditionInput) {
                    deleteClient(input: $input, condition: $condition) {
                      clientId
                      clientName
                      leveraged
                      multiTimezone
                      migrations {
                        nextToken
                        startedAt
                        __typename
                      }
                      stacks {
                        nextToken
                        startedAt
                        __typename
                      }
                      userCount
                      timeZone
                      purchasedStoraged
                      internalSubnets
                      osVersion
                      sqlDriveLetter
                      createdAt
                      updatedAt
                      _version
                      _deleted
                      _lastChangedAt
                      __typename
                    }
                }
            `;

            let variables = {
                input: {
                    clientId: clientId,
                    _version: clientVersion
                }
            }

            await Graphql.query(deleteClient, variables);
        }

        return pushData();
    }

    static editClient(data) {
        const pushData = async () => {
            const updateClient  = `
                mutation UpdateClient($input: UpdateClientInput!, $condition: ModelClientConditionInput) {
                    updateClient(input: $input, condition: $condition) {
                        leveraged
                        multiTimezone
                        userCount
                        timeZone
                        purchasedStoraged
                        internalSubnets
                        osVersion
                        sqlDriveLetter
                    }
                }
            `;

            let variables = {
                input: data,
            }

            return await Graphql.query(updateClient, variables);
        }

        return pushData();
    }

    static assignStack(stackId, clientId) {
        const chosenStack   = this.getChosenStackVersion(stackId);
        const pushData      = async () => {
            const updateStack  = `
                mutation UpdateStack(
                    $input: UpdateStackInput!
                    $condition: ModelStackConditionInput
                ) {
                    updateStack(input: $input, condition: $condition) {
                        clientStacksClientId
                        _version
                    }
                }
            `;

            let variables = {
                input: {
                    id: stackId,
                    clientStacksClientId: clientId,
                    _version: chosenStack._version
                }
            }

            return await Graphql.query(updateStack, variables);
        }

        return pushData();
    }

    static getChosenStackVersion(stackId) {
        const fetchData = async () => {
            const getStack   = `
                query GetStack($id: ID!) {
                    getStack(id: $id) {
                        _version
                    }
                }
            `;

            let variables = {
                id: stackId
            }

            await Graphql.query(getStack, variables).then(async (gq) => {
                // TODO - why doesn't getStack have filter options? filtering this after the query for now...
                if (gq.getStack._deleted === true) {
                    return {};
                }

                return gq.getStack;
            });
        };

        return fetchData();
    }

    static createMigration(data) {
        const pushData = async () => {
            const createMigration  = `
                mutation CreateMigration($input: CreateMigrationInput!, $condition: ModelMigrationConditionInput) {
                    createMigration(input: $input, condition: $condition) {
                        id
                        _version
                        _deleted
                        _lastChangedAt
                    }
                }
            `;

            let variables = {
                input: data,
            }

            return await Graphql.query(createMigration, variables);
        }

        return pushData();
    }
}

export default Queries;