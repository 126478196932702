import { useParams, useNavigate } from "react-router-dom";

export default function MigrationDetail() {
    const { migrationId }   = useParams();
    const navigate          = useNavigate();

    function navigateToMigrationList() {
        navigate('/migrations');
    }

    return (
        <h1>
            Migration detail for {migrationId} goes here
        </h1>
    );
}