import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import {createBrowserRouter, RouterProvider, Outlet, useLocation} from "react-router-dom";

import ErrorPage from "./layout/errorPage";
import Nav from './layout/nav';

import ClientList from "./layout/environmentManager/client/clientList";
import ClientDetail from "./layout/environmentManager/client/clientDetail";

import ClientServicesAdmin from "./layout/environmentManager/cssa/clientServicesAdmin";
import ClientServicesAdminDetail from "./layout/environmentManager/cssa/clientServicesAdminDetail";
import ClientServicesSupport from "./layout/environmentManager/cssa/clientServicesSupport";

import MigrationList from "./layout/environmentManager/migration/migrationList";
import MigrationDetail from "./layout/environmentManager/migration/migrationDetail";
import PodList from "./layout/environmentManager/pod/podList";
import PodDetail from "./layout/environmentManager/pod/podDetail";
import StackList from "./layout/environmentManager/stack/stackList";
import StackDetail from "./layout/environmentManager/stack/stackDetail";
import TerminalServersClientList from "./layout/environmentManager/terminalServersClient/terminalServersClientList";
import TerminalServersClientDetail from "./layout/environmentManager/terminalServersClient/terminalServersClientDetail";

import Auth from "./lib/auth";
import {useEffect} from "react";

function App() {
    async function auth() {
        const qs        = window.location.search;
        const params    = new URLSearchParams(qs);
        const code      = params.get('code');

        if (params.get('signOut')) {
            Auth.signOut();
        }

        if (code) {
            return await Auth.setTokensFromAuthCode(code);
        } else {
            return Auth.refreshTokens();
        }
    }

    function ScrollToTop({ children }) {
        const { pathname } = useLocation();

        useEffect(() => {
            const canControlScrollRestoration = 'scrollRestoration' in window.history
            if (canControlScrollRestoration) {
                window.history.scrollRestoration = 'manual';
            }

            window.scrollTo(0, 0);
        }, [pathname]);

        return children;
    }

    const appElement    = (mainElement) => {
        return (
            <div className="App">
                <Nav />
                <ScrollToTop />
                <div className="appContainer">
                    <div id="content" className="page">
                        {mainElement}
                    </div>
                </div>
            </div>
        );
    }

    // Check auth every minute
    setInterval(async () => {
        try {
            await auth();
        } catch (e) {
            console.error(e);
        }
    }, 60*1000);

    let index       = <ClientList />;
    const router    = createBrowserRouter([
        {
            path: '/',
            element: appElement(<Outlet />),
            errorElement: appElement(<ErrorPage />),
            children: [
                {
                    index: true,
                    element: index,
                    loader: async () => {
                        await auth();
                        return true;
                    },
                },
                {
                    path: 'clients',
                    element: <ClientList />,
                    loader: async () => {
                        await auth();
                        return true;
                    },
                },
                {
                    path: 'clients/:clientId',
                    element: <ClientDetail />,
                    loader: async () => {
                        await auth();
                        return true;
                    },
                },
                {
                    path: 'client-services-system-access/admin',
                    element: <ClientServicesAdmin />,
                    loader: async () => {
                        await auth();
                        return true;
                    },
                },
                {
                    path: 'client-services-system-access/admin/:clientId',
                    element: <ClientServicesAdminDetail />,
                    loader: async () => {
                        await auth();
                        return true;
                    },
                },
                { path: 'client-services-system-access',
                  element: <ClientServicesSupport />,
                    loader: async () => {
                        await auth();
                        return true;
                    },
                },
                {
                    path: 'migrations',
                    element: <MigrationList />,
                    loader: async () => {
                        await auth();
                        return true;
                    },
                },
                {
                    path: 'migrations/:migrationId',
                    element: <MigrationDetail />,
                    loader: async () => {
                        await auth();
                        return true;
                    },
                },
                {
                    path: 'pods',
                    element: <PodList />,
                    loader: async () => {
                        await auth();
                        return true;
                    },
                },
                {
                    path: 'pods/:podId',
                    element: <PodDetail />,
                    loader: async () => {
                        await auth();
                        return true;
                    },
                },
                {
                    path: 'stacks',
                    element: <StackList />,
                    loader: async () => {
                        await auth();
                        return true;
                    },
                },
                {
                    path: 'stacks/:stackId',
                    element: <StackDetail />,
                    loader: async () => {
                        await auth();
                        return true;
                    },
                },
                {
                    path: 'terminal-servers',
                    element: <TerminalServersClientList />,
                    loader: async () => {
                        await auth();
                        return true;
                    },
                },
                {
                    path: 'terminal-servers/:clientId',
                    element: <TerminalServersClientDetail />,
                    loader: async () => {
                        await auth();
                        return true;
                    },
                },
            ]
        }
    ]);

    return (
        <RouterProvider router={router} />
    );
}

export default App;
