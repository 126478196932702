import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";
import Queries from "./queries";

class Modals {
    static addClientModal(showAddClientModal, handleCloseAddClientModal, handleSubmitAddClientModal) {
        return (
            <Modal show={showAddClientModal} onHide={handleCloseAddClientModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Create New Client
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="createClient" onSubmit={e => { e.preventDefault(); }}>
                        <div className="mb-3">
                            <label>Client ID *</label><br />
                            <input type="text" name="clientId" required="required" />
                        </div>
                        <div className="mb-3">
                            <label>Client Name *</label><br />
                            <input type="text" name="clientName" required="required" />
                        </div>
                        <div className="mb-3">
                            <label>Leveraged?</label><br />
                            <div className="form-check form-switch">
                                <input type="checkbox" name="leveraged" className="form-check-input" />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label>Multi-timezone?</label><br />
                            <div className="form-check form-switch">
                                <input type="checkbox" name="multiTimezone" className="form-check-input" />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label>User Count</label><br />
                            <input type="number" name="userCount" />
                        </div>
                        <div className="mb-3">
                            <label>Customer Time Zone</label><br />
                            <select name="timeZone">
                                <option></option>
                                <option value="AT">AT</option>
                                <option value="AZ">AZ</option>
                                <option value="CT">CT</option>
                                <option value="ET">ET</option>
                                <option value="MT">MT</option>
                                <option value="PT">PT</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label>Additional Client Purchased Storage</label><br />
                            <input type="number" name="purchasedStoraged" required="required" />
                        </div>
                        <div className="mb-3">
                            <label>Internal Subnets (one entry per line)</label><br />
                            <textarea name="internalSubnets"></textarea>
                        </div>
                        <div className="mb-3">
                            <label>OS</label><br />
                            <select name="osVersion">
                                <option value="Server2019">Server2019</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label>SQL Drive Letter</label><br />
                            <select name="sqlDriveLetter">
                                <option></option>
                                <option value="D">D</option>
                                <option value="E">E</option>
                                <option value="F">F</option>
                                <option value="G">G</option>
                                <option value="H">H</option>
                                <option value="I">I</option>
                                <option value="J">J</option>
                                <option value="K">K</option>
                                <option value="L">L</option>
                                <option value="M">M</option>
                                <option value="N">N</option>
                                <option value="O">O</option>
                                <option value="P">P</option>
                                <option value="Q">Q</option>
                                <option value="R">R</option>
                                <option value="S">S</option>
                                <option value="T">T</option>
                                <option value="U">U</option>
                                <option value="V">V</option>
                            </select>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseAddClientModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmitAddClientModal}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    static deleteClientModal(client, showDeleteClientModal, handleCloseDeleteClientModal, handleSubmitDeleteClientModal) {
        return (
            <Modal show={showDeleteClientModal} onHide={handleCloseDeleteClientModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Confirm Deletion
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span className="fw-bold text-danger">Are you sure you want to delete {client.clientName}?</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteClientModal}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleSubmitDeleteClientModal}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    static editClientModal(client, showEditClientModal, handleCloseEditClientModal, handleSubmitEditClientModal) {
        return (
            <Modal show={showEditClientModal} onHide={handleCloseEditClientModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Edit Client {client.clientName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="editClient" onSubmit={e => { e.preventDefault(); }}>
                        <div className="mb-3">
                            <label>Client ID *</label><br />
                            <input type="text" name="clientId" disabled="disabled" required="required" value={client.clientId}/>
                        </div>
                        <div className="mb-3">
                            <label>Client Name *</label><br />
                            <input type="text" name="clientName" required="required" defaultValue={client.clientName} />
                        </div>
                        <div className="mb-3">
                            <label>Leveraged?</label><br />
                            <div className="form-check form-switch">
                                <input type="checkbox" name="leveraged" className="form-check-input" defaultChecked={client.leveraged} />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label>Multi-timezone?</label><br />
                            <div className="form-check form-switch">
                                <input type="checkbox" name="multiTimezone" className="form-check-input" defaultChecked={client.multiTimezone} />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label>User Count</label><br />
                            <input type="number" name="userCount" defaultValue={client.userCount} />
                        </div>
                        <div className="mb-3">
                            <label>Customer Time Zone</label><br />
                            <select name="timeZone">
                                <option></option>
                                <option selected={client.timeZone === 'AT' ? 'selected' : false} value="AT">AT</option>
                                <option selected={client.timeZone === 'AZ' ? 'selected' : false} value="AZ">AZ</option>
                                <option selected={client.timeZone === 'CT' ? 'selected' : false} value="CT">CT</option>
                                <option selected={client.timeZone === 'ET' ? 'selected' : false} value="ET">ET</option>
                                <option selected={client.timeZone === 'MT' ? 'selected' : false} value="MT">MT</option>
                                <option selected={client.timeZone === 'PT' ? 'selected' : false} value="PT">PT</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label>Additional Client Purchased Storage</label><br />
                            <input defaultValue={client.purchasedStoraged} type="number" name="purchasedStoraged" required="required" />
                        </div>
                        <div className="mb-3">
                            <label>Internal Subnets (one entry per line)</label><br />
                            <textarea name="internalSubnets">
                                {client.internalSubnetsEditor}
                            </textarea>
                        </div>
                        <div className="mb-3">
                            <label>OS</label><br />
                            <select name="osVersion">
                                <option selected={client.timeZone === 'Server2019' ? 'selected' : false} value="Server2019">Server2019</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label>SQL Drive Letter</label><br />
                            <select name="sqlDriveLetter">
                                <option></option>
                                <option selected={client.sqlDriveLetter === 'D' ? 'selected' : false} value="D">D</option>
                                <option selected={client.sqlDriveLetter === 'E' ? 'selected' : false} value="E">E</option>
                                <option selected={client.sqlDriveLetter === 'F' ? 'selected' : false} value="F">F</option>
                                <option selected={client.sqlDriveLetter === 'G' ? 'selected' : false} value="G">G</option>
                                <option selected={client.sqlDriveLetter === 'H' ? 'selected' : false} value="H">H</option>
                                <option selected={client.sqlDriveLetter === 'I' ? 'selected' : false} value="I">I</option>
                                <option selected={client.sqlDriveLetter === 'J' ? 'selected' : false} value="J">J</option>
                                <option selected={client.sqlDriveLetter === 'K' ? 'selected' : false} value="K">K</option>
                                <option selected={client.sqlDriveLetter === 'L' ? 'selected' : false} value="L">L</option>
                                <option selected={client.sqlDriveLetter === 'M' ? 'selected' : false} value="M">M</option>
                                <option selected={client.sqlDriveLetter === 'N' ? 'selected' : false} value="N">N</option>
                                <option selected={client.sqlDriveLetter === 'O' ? 'selected' : false} value="O">O</option>
                                <option selected={client.sqlDriveLetter === 'P' ? 'selected' : false} value="P">P</option>
                                <option selected={client.sqlDriveLetter === 'Q' ? 'selected' : false} value="Q">Q</option>
                                <option selected={client.sqlDriveLetter === 'R' ? 'selected' : false} value="R">R</option>
                                <option selected={client.sqlDriveLetter === 'S' ? 'selected' : false} value="S">S</option>
                                <option selected={client.sqlDriveLetter === 'T' ? 'selected' : false} value="T">T</option>
                                <option selected={client.sqlDriveLetter === 'U' ? 'selected' : false} value="U">U</option>
                                <option selected={client.sqlDriveLetter === 'V' ? 'selected' : false} value="V">V</option>
                            </select>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditClientModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmitEditClientModal}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    static assignStackModal(clientId, availableStacks, showAssignStackModal, handleCloseAssignStackModal) {
        return (
            <Modal show={showAssignStackModal} onHide={handleCloseAssignStackModal} backdrop="static" className="modal-fullscreen">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Assign Stack
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className="lynx-table table table-striped table-hover table-bordered">
                        <thead>
                        <tr>
                            <th>
                                Stack Name
                            </th>
                            <th>
                                Primary AZ
                            </th>
                            <th>
                                Primary CIDR
                            </th>
                            <th>
                                Secondary AZ
                            </th>
                            <th>
                                Secondary CIDR
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {availableStacks.map(s => (
                            <tr style={{ cursor: 'pointer' }} onClick={() => Queries.assignStack(s.id, clientId).then(() => window.location.reload())} key={s.id}>
                                <td>
                                    {s.stackName}
                                </td>
                                <td>
                                    {s.az1}
                                </td>
                                <td>
                                    {s.az1Cidr}
                                </td>
                                <td>
                                    {s.az2}
                                </td>
                                <td>
                                    {s.az2Cidr}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>
        );
    }

    static createMigrationModal(clientId, showCreateMigrationModal, handleCloseCreateMigrationModal, handleSubmitCreateMigrationModal) {
        return (
            <Modal show={showCreateMigrationModal} onHide={handleCloseCreateMigrationModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Create New Migration
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="createMigration" onSubmit={e => { e.preventDefault(); }}>
                        <div className="mb-3">
                            <label>Migration Name *</label><br />
                            <input type="text" name="migrationName" required="required" />
                        </div>
                        <div className="mb-3">
                            <label>Estimated Start</label><br />
                            <input type="date" name="scheduledStart" />
                        </div>
                        <div className="mb-3">
                            <label>Estimated Cutover</label><br />
                            <input type="date" name="scheduledCutover" />
                        </div>
                        <div className="mb-3">
                            <label>Status</label><br />
                            <select>
                                <option value="">Please select an option</option>
                                <option value="NotStarted">Not started</option>
                                <option value="InProgress">In progress</option>
                                <option value="OnHold">On hold</option>
                                <option value="Blocked">Blocked</option>
                                <option value="Complete">Complete</option>
                                <option value="NotApplicable">Not applicable</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label>Use Database Migration Service (DMS)</label><br />
                            <div className="form-check form-switch">
                                <input type="checkbox" name="useDms" className="form-check-input" />
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCreateMigrationModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmitCreateMigrationModal}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

}

export default Modals;