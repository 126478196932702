import { useParams, useNavigate } from "react-router-dom";
import PodQueries from "../../../lib/pod/queries";
import Queries from "../../../lib/client/queries";
import Utils from "../../../lib/utils";
import Modals from "../../../lib/pod/modals";
import { useEffect, useState } from "react";
import Graphql from "../../../lib/graphql";
import { ScaleLoader } from "react-spinners";
import Auth from "../../../lib/auth";
import {Badge} from "react-bootstrap";

export default function PodDetail() {
    const { podId } = useParams();
    const navigate  = useNavigate();
    const [pod, setPod] = useState({})
    const [stacks, setStacks] = useState([])
    const [availableStacks, setAvailableStacks] = useState([])
    const [podClientCount, setPodClientCount] = useState([])
    const [podUserCounts, setUserCounts] = useState([])

    


    const [loading, setLoading]                 = useState(true);

    const [deleteModelModalOpen, setDeleteModelModalOpen] = useState(false)
    const [showDeletePodModal, setShowDeletePodModal]         = useState(false);
    const [showEditPodModal, setShowEditPodModal]             = useState(false);
    const [showCreateStackModal, setShowCreateStackModal]           = useState(false);
   

    const handleCloseDeletePodModal      = () => setShowDeletePodModal(false);
    const handleShowDeletePodModal       = () => setShowDeletePodModal(true);
    const handleCloseEditPodModal        = () => setShowEditPodModal(false);
    const handleShowEditPodModal         = () => setShowEditPodModal(true);
    const handleCloseCreateStackModal       = () => setShowCreateStackModal(false);
    const handleShowCreateStackModal        = () => setShowCreateStackModal(true);

    useEffect(() => {
        if(!Auth.groupCheck('EnvironmentAdmins') && !Auth.groupCheck('MigrationAdmins') && !Auth.groupCheck('MigrationEngineers')) {
            const error = new Error();

            error.message   = 'Not Authorized';
            error.stack     = null;
            error.httpError = 401;

            throw error;
        }
    }, []);


    
    useEffect(() => {
        const fetchData = async () => {
            const getPod   = `
              query GetPod($id: ID!) {
                getPod(id: $id) {
                    id
                    podName
                    domain
                    domainUser
                    timeZone
                    podSubId
                    awsAccountId
                    awsRegion
                    multiTimezone
                    vpcId
                    vpcCidr
                    securityGroupFile
                    securityGroupSql
                    securityGroupMgmt
                    securityGroupAD
                    sqlAddress
                    sqlName
                    sqlAz
                    sqlReportAddress
                    sqlReportName
                    sqlReportAz
                    fileServerAddress
                    fileServerName
                    fileServerAz
                    stacks {
                      items {
                        id
                        stackName
                        az1
                        az1Cidr
                        az2
                        az2Cidr
                        clientStacksClientId
                      }
                    }
                    dnsIpPrimary
                    dnsIpSecondary
                    tgwAttachmentId
                    medFusionCidr
                    instanceKeyPair
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                    __typename
                    }
                }
            `;
    
            let variables = {
                    id: podId
                }
    
            await Graphql.query(getPod, variables).then(async (gq) => {
                console.log("GraphQL response for getPod:", gq);

                if (!gq || !gq.getPod) {
                    console.error("getPod returned null or undefined");
                    return {};
                }

                if (gq.getPod._deleted === true) {
                    return {};
                }

                setStacks(gq.getPod.stacks.items);
    
                return setPod(gq.getPod);
            });
        };
    
            fetchData().then(async () => {
                let availableStacks = await Queries.getAvailableStacks();
                setAvailableStacks(availableStacks);
            }).then(() => {
                setLoading(false);
            });
        }, [podId]);

    
    useEffect(() => {
        const fetchData = async () => {
            const listClients   = `
                query ListClients(
                    $clientId: ID
                    $filter: ModelClientFilterInput
                    $limit: Int
                    $nextToken: String
                    $sortDirection: ModelSortDirection
                ) {
                    listClients(
                      clientId: $clientId
                      filter: $filter
                      limit: $limit
                      nextToken: $nextToken
                      sortDirection: $sortDirection
                    ) {
                        items {
                          clientId
                          clientName
                          userCount
                          timeZone
                          leveraged
                          multiTimezone
                          stacks {
                            items {
                              pod {
                                id
                                podName
                              }
                            }
                          }
                        }
                        nextToken
                    }
                }
            `;

            let variables = {
                filter: {
                    _deleted: {
                        ne: true
                    }
                }
            }
    
            // const variables = { clientId: clientId }; // Pass the clientId
    
            await Graphql.queryAllResults(listClients, variables).then((gq) => {
                console.log("GraphQL response for listClients:", gq);
    
                if (!gq || !gq.listClients || !gq.listClients.items) {
                    console.error("listClients returned null or undefined");
                    return;
                }
    
                // Filter clients associated with a specific podId and sum their userCounts
                const podUserCounts = gq.listClients.items
                    .filter(client => 
                        client.stacks.items.some(stack => stack.pod && stack.pod.id === podId)
                    ) // Filter clients that are linked to the specific pod
                    .reduce((total, client) => total + (client.userCount || 0), 0); // Sum the userCount of these clients
                    
                console.log("pod user count:", podUserCounts)
                setUserCounts(podUserCounts); // Set the total user count for all clients linked to the pod
    
                // calculate the number of clients linked to the pod
                const podClientCount = gq.listClients.items
                    .filter(client => 
                        client.stacks.items.some(stack => stack.pod && stack.pod.id === podId)
                    ) // Filter clients that are linked to the specific pod
                    .length;

                console.log("pod client count:", podClientCount)
                setPodClientCount(podClientCount); // Set the number of clients linked to the pod
    
            });
        };
    
        fetchData().then(() => setLoading(false));
    }, [podId]);
    


    async function handleSubmitDeletePodModal() {
        await PodQueries.deletePod(podId,pod._version)
        return navigate('/pods')
    }
    
    function handleSubmitEditPodModal() {
            const form          = document.getElementById("editPod");
            const formData      = new FormData(form);
            const formDataObj   = {};
    
            formData.forEach((value, key) => formDataObj[key] = value);
    
            formDataObj.podName            = pod.podName;
            formDataObj.multiTimezone       = formDataObj.multiTimezone === "on";
    
            if (!formDataObj.podName || !formDataObj.domain) {
                alert('Missing required fields');
                return false;
            }
    
            return PodQueries.editPod(formDataObj).then(() => window.location.reload());
        }

    
    // Function to render pod client count with a colored circle and custom tooltip on hover
    const [isHovered, setIsHovered] = useState(false);
    const [isHoveredClient, setIsHoveredClient] = useState(false);
    
    const renderPodClientCount = (podClientCount) => {
        const isMaxClients = podClientCount >= 10;
        const tooltipMessage = isMaxClients
            ? `ALERT -- The POD has reached the maximum number of clients if this is a leveraged POD!`
            : `The POD, if leveraged, does not yet have the maximum number (10) of clients assigned to it`;

        // Hover effect to show tooltip
        

        return (
            <div style={{ position: 'relative', display: 'inline-block' }}>
                {/* Circle with podClientCount inside */}
                <span
                    style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        backgroundColor: isMaxClients ? '#E57373' : '#81C784',
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '0.9em',
                        cursor: 'pointer',
                        position: 'relative'
                    }}
                    onMouseEnter={() => setIsHovered(true)} // Show tooltip on hover
                    onMouseLeave={() => setIsHovered(false)} // Hide tooltip when hover ends
                >
                    {podClientCount}

                    {/* Tooltip - visibility controlled by isHovered state */}
                    {isHovered && (
                        <div
                            style={{
                                backgroundColor: 'black',
                                color: 'white',
                                textAlign: 'right',
                                borderRadius: '4px',
                                padding: '5px',
                                position: 'absolute',
                                zIndex: 1,
                                top: '80%',
                                left: '80%',
                                transform: 'translateX(0)',
                                fontSize: '1.1em',
                                whiteSpace: 'nowrap',
                                opacity: 1,
                                transition: 'opacity 0.3s'
                            }}
                        >
                            {tooltipMessage}
                        </div>
                    )}
                </span>
            </div>
        );
    };




    // Function to render a badge with a tooltip for podUserCounts
    const renderPodUsrCount = (podUserCounts) => {
        const isMaxReached = podUserCounts >= 350;
        const tooltipMessage = isMaxReached 
            ? `ALERT -- The POD has reached the maximum number of client end users assigned to it!`
            : `The POD, if leveraged, does not yet have the maximum number of client end users assigned to it`;
    
        return (
            <div style={{ position: 'relative', display: 'inline-block' }}>
                {/* Green circle with the podUserCounts inside */}
                <span 
                    style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        backgroundColor: isMaxReached ? '#E57373' : '#81C784',
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '0.9em',
                        cursor: 'pointer',
                        position: 'relative' 
                    }}
                    onMouseEnter={() => setIsHoveredClient(true)} // Show tooltip on hover
                    onMouseLeave={() => setIsHoveredClient(false)} // Hide tooltip when hover ends
                >
                    {podUserCounts}

                    {/* Tooltip - visibility controlled by isHoveredClient state */}
                    {isHoveredClient && (
                        <div
                            style={{
                                backgroundColor: 'black',
                                color: 'white',
                                textAlign: 'right',
                                borderRadius: '4px',
                                padding: '5px',
                                position: 'absolute',
                                zIndex: 1,
                                top: '80%',
                                left: '80%',
                                transform: 'translateX(0)',
                                fontSize: '1.1em', 
                                whiteSpace: 'nowrap',
                                opacity: 1,
                                transition: 'opacity 0.3s'
                            }}
                        >
                            {tooltipMessage}
                        </div>
                    )}
                </span>
            </div>
        );
    };


    function podDetails() {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-sm">
                            <div className="container border border-light-subtle">
                                <div className="row border border-light-subtle">
                                    <div className="col fw-bold">
                                        Pod Name
                                    </div>
                                    <div className="col-sm">
                                        {pod.podName}
                                    </div>
                                </div>
                                <div className="row border border-light-subtle">
                                    <div className="col fw-bold">
                                        AWS Account ID
                                    </div>
                                    <div className="col-sm">
                                        {pod.awsAccountId}
                                    </div>
                                </div>
                                <div className="row border border-light-subtle">
                                    <div className="col fw-bold">
                                        AWS Region
                                    </div>
                                    <div className="col-sm">
                                        {Utils.formatRegion(pod.awsRegion)}
                                    </div>
                                </div>
                                <div className="row border border-light-subtle">
                                    <div className="col fw-bold">
                                        Domain
                                    </div>
                                    <div className="col-sm">
                                        {pod.domain}
                                    </div>
                                </div>
                                <div className="row border border-light-subtle">
                                    <div className="col fw-bold">
                                        Security Group - Active Directory
                                    </div>
                                    <div className="col-sm">
                                        {pod.securityGroupAD}
                                    </div>
                                </div>
                                <div className="row border border-light-subtle">
                                    <div className="col fw-bold">
                                        Security Group - File Server
                                    </div>
                                    <div className="col-sm">
                                        {pod.securityGroupFile}
                                    </div>
                                </div>
                                <div className="row border border-light-subtle">
                                    <div className="col fw-bold">
                                        Security Group - Management
                                    </div>
                                    <div className="col-sm">
                                        {pod.securityGroupMgmt}
                                    </div>
                                </div>
                                <div className="row border border-light-subtle">
                                    <div className="col fw-bold">
                                        Security Group - SQL
                                    </div>
                                    <div className="col-sm">
                                        {pod.securityGroupSql}
                                    </div>
                                </div>
                                <div className="row border border-light-subtle">
                                    <div className="col fw-bold">
                                       Time Zone
                                    </div>
                                    <div className="col-sm">
                                        {pod.timeZone}
                                    </div>
                                </div>
                                <div className="row border border-light-subtle">
                                    <div className="col fw-bold">
                                       VPC CIDR
                                    </div>
                                    <div className="col-sm">
                                        {pod.vpcCidr}
                                    </div>
                                </div>
            
                                <div className="row border border-light-subtle">
                                    <div className="col fw-bold">
                                        Multi-timezone
                                    </div>
                                    <div className="col-sm">
                                        {pod.multiTimezone ? 'Yes' : 'No'}
                                    </div>
                                </div>
                                <div className="row border border-light-subtle">
                                    <div className="col fw-bold">
                                        Primary DNS IP
                                    </div>
                                    <div className="col-sm">
                                        {pod.dnsIpPrimary}
                                    </div>
                                </div>
                                <div className="row border border-light-subtle">
                                    <div className="col fw-bold">
                                        Secondary DNS IP
                                    </div>
                                    <div className="col-sm">
                                        {pod.dnsIpSecondary}
                                    </div>
                                </div>
                                <div className="row border border-light-subtle">
                                    <div className="col fw-bold">
                                        Total Client End-User Count for POD
                                    </div>
                                    <div className="col-sm">
                                        {renderPodUsrCount(podUserCounts)}
                                    </div>
                                </div>
                                <div className="row border border-light-subtle">
                                    <div className="col fw-bold">
                                        Total Clients assigned to POD
                                    </div>
                                    <div className="col-sm">
                                        {renderPodClientCount(podClientCount)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col text-end">
                            <button onClick={handleShowDeletePodModal} type="button" className="btn btn-light d-inline-block m-1">Delete</button>
                            <button onClick={handleShowEditPodModal} type="button" className="btn btn-success d-inline-block m-1">Edit Details</button>
                            {Modals.deletePodModal(pod, showDeletePodModal, handleCloseDeletePodModal, handleSubmitDeletePodModal)}
                            {/* {Modals.editPodModal(pod, showEditPodModal, handleCloseEditPodModal, handleSubmitEditPodModal)} */}
                        </div>
                    </div>
                </div>
            );
    }
    
    function stacksSection() {
            return(
                <>
                    <div className={'mw-100 clearfix text-end float-end'  + (availableStacks <= 0 ? ' not-allowed' : '')}>
                        <button type="button" className="btn btn-success d-inline-block mt-3 mb-3" disabled={availableStacks.length <= 0} onClick={() => handleShowCreateStackModal()}>Create Stack</button>
                    </div>
                    <table className="lynx-table table table-striped table-hover table-bordered">
                        <thead>
                            <tr>
                                <th>
                                    Stack ID
                                </th>
                                <th>
                                    AZ 1
                                </th>
                                <th>
                                    AZ 2
                                </th>
                                <th>
                                    Primary CIDR
                                </th>
                                <th>
                                    Secondary CIDR
                                </th>
                                <th>
                                    Is Assigned?
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {stacks.map(s => (
                                <tr style={{ cursor: 'pointer' }} onClick={() => navigate(`/stacks/${s.id}`,{ podName: pod.podName })} key={s.id}>
                                    <td>
                                        {s.stackName}
                                    </td>
                                    <td>
                                        {Utils.formatRegion(s.az1)}
                                    </td>
                                    <td>
                                        {Utils.formatRegion(s.az2)} 
                                    </td>
                                    <td>
                                        {s.az1Cidr}
                                    </td>
                                    <td>
                                        {s.az2Cidr}
                                    </td>
                                    <td>
                                        {s.clientStacksClientId ? "Yes" : "No"}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            );
    }
    
    return (
            <>
                <h1>
                    {`Pod Details - ${pod.podName}`} 
                </h1>
    
                <hr />
    
                <div className="mw-100 clearfix">
                    {podDetails()}
                    <div className="loading_wrapper">
                        <ScaleLoader loading={loading} />
                    </div>
                </div>
                <div className="mw-100 mt-5 clearfix">
                    <h2>Stacks</h2>
                    <hr />
                    {stacksSection()}
                    {/* {Modals.assignStackModal(pod.podId, availableStacks, showCreateStackModal, handleCloseCreateStackModal)} */}
                    <div className="loading_wrapper">
                        <ScaleLoader loading={loading} />
                    </div>
                </div>
            </>
        );
}
