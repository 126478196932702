
class Paginate {
    static getPageCount(pageSize, itemCount = 1) {
        return Math.ceil(itemCount / pageSize)
    }

    static getPageItems(items, pageSize, pageNumber = 1) {
        const startingIndex = (pageNumber - 1) * pageSize;
        const endingIndex   = startingIndex + pageSize;

        return items.slice(startingIndex, endingIndex)
    }

    static getPagination(pageSize, pageNumber, itemCount = 1) {
        let htmlOutput  = [];
        let pages       = this.getPageCount(pageSize, itemCount);

        for (let i = 1; i <= pages; i++) {
            let disablePrevious = i === 1;
            let disableNext     = i === pages;

            let data = {
                page:               i,
                active:             i === pageNumber,
                disablePrevious:    disablePrevious,
                disableNext:        disableNext
            };

            htmlOutput.push(data);
        }

        return htmlOutput;
    }

    static setActive(event) {
        document.querySelectorAll('.pagination .page-item .page-link').forEach((e) => {
            e.classList.remove(...this.activePageButton.split(" "));
        });

        return event.currentTarget.className += ' ' + this.activePageButton;
    }

    static getPaginationHtml(pageSize, currentPage, data, handleOnChangeCallback, additionalClasses = ' ') {
        return this.getPagination(pageSize, currentPage, data.length).map(c => (
            <li className="page-item"><a className={'page-link ' + additionalClasses + (c.active ? ' ' + this.activePageButton : this.defaultPageButton)} onClick={(e) => {e.preventDefault(); handleOnChangeCallback(e)}}>{c.page}</a></li>
        ))
    }

    static activePageButton     = 'active text-white bg-success border-success';
    static defaultPageButton    = 'text-black border-gray';
}

export default Paginate;