import Fuse from "fuse.js";

class Search {
    static json(keyword, keys, dataset) {
        const fuseOptions = {
            minMatchCharLength: 0,
            threshold: 0,
            keys: keys
        }

        const fuse      = new Fuse(dataset, fuseOptions);
        let results     = fuse.search(keyword);
        let resultSet   = [];

        for (let i = 0; i < results.length; i++) {
            resultSet[i] = results[i].item;
        }

        return resultSet;
    }

    static getSearchHtml(handleSearchCallback, inputId='search') {
        return (
            <div className="form-group">
                <label htmlFor="search">
                    Search:
                </label>
                <input id={inputId} style={{width:"90%",display:"inline-block"}} onChange={(e) => {handleSearchCallback(e.target.value)}} type="text" className="form-control"/>

                <span className="searchClear" onClick={() => {if(document.getElementById(inputId).value.length){handleSearchCallback('');} document.getElementById(inputId).value = '';}} style={{width:"1em",height:"1em",marginLeft:"-2em"}}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="currentColor"></path>
                    </svg>
                </span>
            </div>
        );
    }
}

export default Search;