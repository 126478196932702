import { useParams, useNavigate } from "react-router-dom";

export default function TerminalServersClientDetail() {
    const { clientId }  = useParams();
    const navigate      = useNavigate();

    function navigateToTerminalServersClientList() {
        navigate('/terminal-servers');
    }

    return (
        <h1>
            TS Client detail for {clientId} goes here
        </h1>
    );
}