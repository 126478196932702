import Utils from "./utils";


class Graphql {
    /**
     * @param {string} query
     * @param {object} variables
     */
    static async query(query, variables = {}) {
        return await Utils.getSecret(process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT_API_KEY).then(async (xApiKey) => {
            let appsync = await fetch(process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT_URL, {
                method: 'POST',
                headers: {
                    'x-api-key': `${xApiKey}`
                },
                body: JSON.stringify({
                    query: query,
                    variables: variables
                }),
            });

            let response = await appsync.json();

            return response.data;
        });
    }

    /**
     * @param {string} query
     * @param {object} variables
     * @param {array} _results
     */
    static async queryAllResults(query, variables = {}, _results = []) {
        return await this.query(query, variables).then((items) => {
            let objKey          = Object.keys(items)[0];

            _results.push(...items[objKey].items);

            if (!items[objKey].nextToken) {
                let resultObject        = {};
                resultObject[objKey]    = {
                    items: _results
                }

                return resultObject;
            } else {
                variables.limit     = 1000;
                variables.nextToken = items[objKey].nextToken;

                return this.queryAllResults(query, variables, _results);
            }
        });
    }
}

export default Graphql;