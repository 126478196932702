import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Auth from "../../../lib/auth";
import Graphql from "../../../lib/graphql";
import Paginate from "../../../lib/paginate";
import Search from "../../../lib/search";

import { ScaleLoader } from "react-spinners";

export default function StackList() {
    useEffect(() => {
        if(!Auth.groupCheck('EnvironmentAdmins') && !Auth.groupCheck('MigrationAdmins') && !Auth.groupCheck('MigrationEngineers')) {
            const error = new Error();

            error.message   = 'Not Authorized';
            error.stack     = null;
            error.httpError = 401;

            throw error;
        }
    }, []);

    const navigate                          = useNavigate();
    const [stacks, setStacks]               = useState([]);
    const [visibleStacks, setVisibleStacks] = useState([]);
    const [loading, setLoading]             = useState(true);
    const [currentPage, setCurrentPage]     = useState(1);
    const [totalPages, setTotalPages]       = useState(1);

    const pageSize  = 25;

    useEffect(() => {
        const fetchData = async () => {
            const listStacks   = `
                query ListStacks(
                    $filter: ModelStackFilterInput
                    $limit: Int
                    $nextToken: String
                ) {
                    listStacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
                        items {
                            id
                            stackName
                            amiTermServ
                            amiTermServBase
                            amiAncServ
                            amiPxPServ
                            az1
                            az1Cidr
                            az2
                            az2Cidr
                            dnsIpSecondary
                            tgwAttachmentId
                            medFusionCidr
                            createBaseTermServer
                            createPxpServer
                            instanceTypeTermServ
                            pod {
                                podName
                            }
                            createdAt
                            updatedAt
                            _version
                            _deleted
                            _lastChangedAt
                            clientStacksClientId
                            podStacksId
                            __typename
                          }
                          nextToken
                          startedAt
                          __typename
                    }
                }
            `;

            let variables = {
                filter: {
                    _deleted: {
                        ne: true
                    }
                }
            }

            await Graphql.queryAllResults(listStacks, variables).then((gq) => {

                // sort by client name ASC. TODO - how can we do this in gql?
                gq.listStacks.items.sort((a, b) => {
                    const nameA = a.stackName.toUpperCase();
                    const nameB = b.stackName.toUpperCase();
                    if (nameA < nameB) { return -1; }
                    if (nameA > nameB) { return 1; }

                    return 0;
                });

                setTotalPages(Paginate.getPageCount(pageSize, gq.listStacks.items.length));
                setVisibleStacks(Paginate.getPageItems(gq.listStacks.items, pageSize));

                return setStacks(gq.listStacks.items);
            });
        };

        fetchData().then(() => setLoading(false));
    }, []);

    const handleChangePage = (event) => {
        Paginate.setActive(event);

        let newPageIndex    = event.target.innerHTML;

        setVisibleStacks(Paginate.getPageItems(stacks, pageSize, newPageIndex));
        setCurrentPage(newPageIndex);
    };

    function handleSearch(keywords) {
        let paginationContainer = document.querySelector('.pagination');

        if (keywords.length <= 0) {
            if (paginationContainer) {
                paginationContainer.style.visibility = 'visible';
            }

            return setVisibleStacks(stacks);
        } else {
            if (paginationContainer) {
                paginationContainer.style.visibility = 'hidden';
            }
        }

        let results = Search.json(keywords, ["stackName"], stacks);

        return setVisibleStacks(results);
    }

    function stackRows(stacks) {
        let rows    = [];
        for (let i = 0; i < stacks.length; i++) {
            rows.push(
                <tr style={{ cursor: 'pointer' }} onClick={() => navigate(`/stacks/${stacks[i].id}`)} key={stacks[i].id}>
                    <td>{stacks[i].stackName}</td>
                    <td>{stacks[i].az1}</td>
                    <td>{stacks[i].az2}</td>
                    <td>{stacks[i].az1Cidr}</td>
                    <td>{stacks[i].az2Cidr}</td>
                    <td>{stacks[i].clientStacksClientId ? "Yes" : "No"}</td>
                    <td>{typeof stacks[i].pod.podName !== "undefined" ? stacks[i].pod.podName : 'N/A'}</td>
                </tr>
            )
        }

        return (
            <tbody>
                {rows}
            </tbody>
        );
    }

    function stacksTable() {
        return (
            <table className="lynx-table table table-striped table-hover table-bordered">
                <thead>
                <tr>
                    <th>
                        Stack Name
                    </th>
                    <th>
                        Primary AZ
                    </th>
                    <th>
                        Secondary AZ
                    </th>
                    <th>
                        Primary CIDR
                    </th>
                    <th>
                        Secondary CIDR
                    </th>
                    <th>
                        Assigned?
                    </th>
                    <th>
                        POD Attached To Stack
                    </th>
                </tr>
                </thead>
                {stackRows(visibleStacks)}
            </table>
        );
    }

    return (
        <>
            <h1>
                All Stacks
            </h1>

            <hr />
            <div className="mw-100 clearfix">
                <div className="float-start m-2">
                    {Search.getSearchHtml(handleSearch)}
                </div>
                <div className="float-end m-3">

                </div>
            </div>
            <div className="mw-100 mt-3 clearfix">
                <ul className="pagination justify-content-center">
                    {Paginate.getPaginationHtml(pageSize, currentPage, stacks, handleChangePage)}
                </ul>
            </div>
            <div className="mw-100 clearfix">
                {stacksTable()}
                <div className="loading_wrapper">
                    <ScaleLoader loading={loading} />
                </div>
            </div>
        </>
    );
}