import pkceChallenge from "pkce-challenge";
import { jwtDecode } from "jwt-decode";

class Auth {
    static async generatePkce() {
        const { code_verifier, code_challenge } = await pkceChallenge();

        return { code_verifier, code_challenge };
    }

    static async setTokensFromAuthCode(authorizationCode) {
        const { code_verifier, code_challenge } = await Auth.generatePkce();

        const cognitoAuthUrl    = process.env.REACT_APP_AWS_COGNITO_LYNX_AUTH_URL;
        const cognitoClientId   = process.env.REACT_APP_AWS_COGNITO_CLIENT_ID;
        const lynxUrl           = process.env.REACT_APP_LYNX_URL;

        localStorage.setItem('verifier', code_verifier);

        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                grant_type: 'authorization_code',
                client_id: cognitoClientId,
                code: authorizationCode,
                redirect_uri: lynxUrl,
                code_verifier: localStorage.getItem('verifier')
            })
        };

        return await fetch(cognitoAuthUrl + '/oauth2/token', requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.access_token && data.id_token && data.refresh_token) {
                    localStorage.setItem('refreshToken', data.refresh_token);
                    localStorage.setItem('accessToken', data.access_token);
                    localStorage.setItem('idToken', data.id_token);
                    localStorage.setItem('groups', JSON.stringify(jwtDecode(data.access_token)['cognito:groups']));
                    localStorage.setItem('name', jwtDecode(data.id_token)['name']);
                    localStorage.setItem('email', jwtDecode(data.id_token)['email']);
                    localStorage.removeItem('verifier');
                }
            });
    }

    static refreshTokens() {
        const cognitoAuthUrl    = process.env.REACT_APP_AWS_COGNITO_LYNX_AUTH_URL;
        const cognitoClientId   = process.env.REACT_APP_AWS_COGNITO_CLIENT_ID;
        const lynxUrl           = process.env.REACT_APP_LYNX_URL;

        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams(
                {
                    grant_type: 'refresh_token',
                    client_id: cognitoClientId,
                    refresh_token: localStorage.getItem('refreshToken')
                }
            )
        };

        fetch(cognitoAuthUrl + '/oauth2/token', requestOptions)
            .then((response) => {
                if (response.status !== 200) {
                    window.location.href = cognitoAuthUrl + '/login?response_type=code&client_id=' + cognitoClientId + '&redirect_uri=' + lynxUrl
                }

                return response.json();
            })
            .then((data) => {
                localStorage.setItem('accessToken', data.access_token);
                localStorage.setItem('idToken', data.id_token);
                localStorage.setItem('groups', JSON.stringify(jwtDecode(data.access_token)['cognito:groups']));
                localStorage.setItem('name', jwtDecode(data.id_token)['name']);
                localStorage.setItem('email', jwtDecode(data.id_token)['email']);
            });
    }

    static signOut() {
        const cognitoAuthUrl    = process.env.REACT_APP_AWS_COGNITO_LYNX_AUTH_URL;
        const cognitoClientId   = process.env.REACT_APP_AWS_COGNITO_CLIENT_ID;
        const lynxUrl           = process.env.REACT_APP_LYNX_URL;

        let requestOptions      = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams(
                {
                    client_id: cognitoClientId,
                    token: localStorage.getItem('refreshToken')
                }
            )
        };

        fetch(cognitoAuthUrl + '/oauth2/revoke', requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                localStorage.clear();
            });
    }

    static groupCheck(group) {
        const groups = localStorage.getItem('groups');

        if(!groups) {
            return false;
        }

        return JSON.parse(groups).includes(group);
    }
}

export default Auth;