import { useParams, useNavigate } from "react-router-dom";

export default function ClientServicesAdminDetail() {
    const { clientId }  = useParams();
    const navigate      = useNavigate();

    function navigateToClientList() {
        navigate('/clients');
    }

    return (
        <h1>
            CSSA Admin detail for {clientId} goes here
        </h1>
    );
}