import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ScaleLoader } from "react-spinners";
import Auth from "../../../lib/auth";

import Graphql from "../../../lib/graphql";
import Paginate from "../../../lib/paginate";
import Search from "../../../lib/search";
import Queries from "../../../lib/client/queries";
import Modals from "../../../lib/client/modals";

export default function ClientList() {
    useEffect(() => {
        if(!Auth.groupCheck('EnvironmentAdmins') && !Auth.groupCheck('MigrationAdmins') && !Auth.groupCheck('MigrationEngineers')) {
            const error = new Error();

            error.message   = 'Not Authorized';
            error.stack     = null;
            error.httpError = 401;

            throw error;
        }
    }, []);

    const navigate                                      = useNavigate();
    const [clients, setClients]                         = useState([]);
    const [visibleClients, setVisibleClients]           = useState([]);
    const [showAddClientModal, setShowAddClientModal]   = useState(false);
    const [loading, setLoading]                         = useState(true);
    const [currentPage, setCurrentPage]                 = useState(1);
    const [totalPages, setTotalPages]                   = useState(1);

    const handleCloseAddClientModal = () => setShowAddClientModal(false);
    const handleShowAddClientModal  = () => setShowAddClientModal(true);

    const pageSize  = 25;

    useEffect(() => {
        const fetchData = async () => {
            const listClients   = `
                query ListClients(
                    $clientId: ID
                    $filter: ModelClientFilterInput
                    $limit: Int
                    $nextToken: String
                    $sortDirection: ModelSortDirection
                ) {
                    listClients(
                      clientId: $clientId
                      filter: $filter
                      limit: $limit
                      nextToken: $nextToken
                      sortDirection: $sortDirection
                    ) {
                        items {
                          clientId
                          clientName
                          userCount
                          timeZone
                          leveraged
                          multiTimezone
                          stacks {
                            items {
                              pod {
                                id
                                podName
                              }
                            }
                          }
                        }
                        nextToken
                    }
                }
            `;

            let variables = {
                filter: {
                    _deleted: {
                        ne: true
                    }
                }
            }

            await Graphql.queryAllResults(listClients, variables).then((gq) => {

                // sort by client name ASC. TODO - how can we do this in gql?
                gq.listClients.items.sort((a, b) => {
                    const nameA = a.clientName.toUpperCase();
                    const nameB = b.clientName.toUpperCase();
                    if (nameA < nameB) { return -1; }
                    if (nameA > nameB) { return 1; }

                    return 0;
                });

                setTotalPages(Paginate.getPageCount(pageSize, gq.listClients.items.length));
                setVisibleClients(Paginate.getPageItems(gq.listClients.items, pageSize));

                return setClients(gq.listClients.items);
            });
        };

        fetchData().then(() => setLoading(false));
    }, []);



    const handleNextPage        = () => {
        setCurrentPage(currentPage + 1);
    };
    const handlePreviousPage    = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleChangePage = (event) => {
        Paginate.setActive(event);

        let newPageIndex    = event.target.innerHTML;

        setVisibleClients(Paginate.getPageItems(clients, pageSize, newPageIndex));
        setCurrentPage(newPageIndex);
    };

    function handleSubmitAddClientModal() {
        const form          = document.getElementById("createClient");
        const formData      = new FormData(form);
        const formDataObj   = {};

        formData.forEach((value, key) => formDataObj[key] = value);

        formDataObj.leveraged           = formDataObj.leveraged === "on";
        formDataObj.multiTimezone       = formDataObj.multiTimezone === "on";
        formDataObj.internalSubnets     = formDataObj.internalSubnets.split('\n');
        formDataObj.userCount           = !formDataObj.userCount ? 0 : formDataObj.userCount;
        formDataObj.purchasedStoraged   = !formDataObj.purchasedStoraged ? 0 : formDataObj.purchasedStoraged;

        if (!formDataObj.clientId || !formDataObj.clientName) {
            alert('Missing required fields');
            return false;
        }

        return Queries.createClient(formDataObj).then(() => window.location.reload());
    }

    function handleSearch(keywords) {
        let paginationContainer = document.querySelector('.pagination');

        if (keywords.length <= 0) {
            if (paginationContainer) {
                paginationContainer.style.visibility = 'visible';
            }

            return setVisibleClients(clients);
        } else {
            if (paginationContainer) {
                paginationContainer.style.visibility = 'hidden';
            }
        }

        let results = Search.json(keywords, ["clientId", "clientName"], clients);

        return setVisibleClients(results);
    }

    function clientRows(clients) {
        let rows    = [];
        for (let i = 0; i < clients.length; i++) {
            rows.push(
                <tr style={{ cursor: 'pointer' }} onClick={() => navigate(`/clients/${clients[i].clientId}`)} key={clients[i].clientId}>
                    <td>{clients[i].clientId}</td>
                    <td>{clients[i].clientName}</td>
                    <td>{clients[i].userCount}</td>
                    <td>{clients[i].timeZone}</td>
                    <td>{clients[i].leveraged ? "Yes" : "No"}</td>
                    <td>{clients[i].multiTimezone ? "Yes" : "No"}</td>
                    <td>
                        {typeof clients[i].stacks.items[0] !== "undefined" ? clients[i].stacks.items[0].pod.podName : 'N/A'}
                    </td>
                </tr>
            )
        }

        return (
            <tbody>
                {rows}
            </tbody>
        );
    }

    function clientsTable() {
        return (
            <table className="lynx-table table table-striped table-hover table-bordered">
                <thead>
                <tr>
                    <th>
                        Client ID
                    </th>
                    <th>
                        Client Name
                    </th>
                    <th>
                        User Count
                    </th>
                    <th>
                        Time Zone
                    </th>
                    <th>
                        Leveraged?
                    </th>
                    <th>
                        Multi-timezone?
                    </th>
                    <th>
                        POD Name Attached To Client
                    </th>
                </tr>
                </thead>
                {clientRows(visibleClients)}
            </table>
        );
    }

    return (
        <>
            <h1>
                All Clients
            </h1>

            <hr />
            <div className="mw-100 clearfix">
                <div className="float-start m-2">
                    {Search.getSearchHtml(handleSearch)}
                </div>
                <div className="float-end m-3">
                    <button className="btn btn-success float-end" onClick={handleShowAddClientModal}>
                        Add Client
                    </button>
                    {Modals.addClientModal(showAddClientModal, handleCloseAddClientModal, handleSubmitAddClientModal)}
                </div>
            </div>
            <div className="mw-100 mt-3 clearfix">
                <ul className="pagination justify-content-center">
                    {Paginate.getPaginationHtml(pageSize, currentPage, clients, handleChangePage)}
                </ul>
            </div>
            <div className="mw-100 clearfix">
                {clientsTable()}
                <div className="loading_wrapper">
                    <ScaleLoader loading={loading} />
                </div>
            </div>
        </>
    );
}